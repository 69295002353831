export const taskStatusConfig = {
  completed: { label: '已完成', value: 'completed', type: 'info' },
  failed: { label: '失敗', value: 'failed', type: 'danger' },
  processing: { label: '進行中', value: 'processing', type: 'action' },
  pending: { label: '等待排程', value: 'pending', type: 'warning' },
}

export const workBookTemplateConfig = {
  memberBasic: { label: '會員管理匯出', value: 'memberBasic' },
  eventPlaybookAwardRecordBasic: { label: '事件行銷發放紀錄匯出', value: 'eventPlaybookAwardRecordBasic' },
  shopPointReportBasic: { label: '點數發放紀錄匯出', value: 'shopPointReportBasic' },
  memberGameRecordBasic: { label: '抽獎紀錄發放紀錄匯出', value: 'memberGameRecordBasic' },
  appointmentVisitorPreOrderBasic: { label: '跨境預約訪客人次紀錄匯出', value: 'appointmentVisitorPreOrderBasic' },
  couponExchangeSerialBasic: { label: '兌換券設定兌換碼管理紀錄匯出', value: 'couponExchangeSerialBasic' },
  couponRecordCreatedBasic: { label: '票券新增紀錄匯出', value: 'couponRecordCreatedBasic' },
  couponRecordUsedBasic: { label: '票券使用紀錄匯出', value: 'couponRecordUsedBasic' },
  classTicketRecordBasic: { label: '堂票新增紀錄匯出', value: 'classTicketRecordBasic' },
  classTicketRecordUseBasic: { label: '堂票使用紀錄匯出', value: 'classTicketRecordUseBasic' },
  memberStoreOrderBasic: { label: '商城訂單紀錄匯出', value: 'memberStoreOrderBasic' },
  memberStoreOrderPointBasic: { label: '點數兌換紀錄匯出', value: 'memberStoreOrderPointBasic' },
  ecommerceOrderBasic: { label: '電商模組訂單記錄匯出', value: 'ecommerceOrderBasic' },
  memberExternalTransactionBasic: { label: '第三方消費紀錄匯出', value: 'memberExternalTransactionBasic' },
  shopCashbackReportBasic: { label: '回饋金報表紀錄匯出', value: 'shopCashbackReportBasic' },
  walletRecordBasic: { label: '儲值金報表紀錄匯出', value: 'walletRecordBasic' },
  serialActivityCodeBasic: { label: '序號登錄活動活動序號管理紀錄匯出', value: 'serialActivityCodeBasic' },
  salesClientLinkBasic: { label: '消費登錄連結紀錄匯出', value: 'salesClientLinkBasic' },
  appointmentReservationBasic: { label: '預約紀錄匯出', value: 'appointmentReservationBasic' },
  subscriptionOrderBasic: { label: '訂閱紀錄匯出', value: 'subscriptionOrderBasic' },
  pointCardUsageLogBasic: { label: '點數卡使用紀錄匯出', value: 'pointCardUsageLogBasic' },
  pointCardRecordBasic: { label: '點數卡新增紀錄匯出', value: 'pointCardRecordBasic' },
  punchCardUsageLogBasic: { label: '次卡使用紀錄匯出', value: 'punchCardUsageLogBasic' },
  punchCardRecordBasic: { label: '次卡新增紀錄匯出', value: 'punchCardRecordBasic' },
}
